import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
// import PageBreadcrumb from "../../components/pagebreadcrumb";
// import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/products/motor-insurance.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";

const Motor = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a721a778fc357870f08ef3"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Motor Insurance" pathname="/" />
            <Container style={{ lineHeight: "130%" }}>
                <img
                    style={{
                        marginTop: "10px",
                        marginBottom: "30px",
                        borderRadius: "10px",
                    }}
                    src={jsonData[0].image}
                    alt="Motor"
                />
                <div
                    style={{
                        marginBottom: "30px",
                    }}
                >
                    <h3>{jsonData[0].title}</h3>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[0].title1}
                    </p>
                    <h4>{jsonData[0].title2}</h4>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[0].text}
                    </p>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[0].text1}
                    </p>
                    <h5>{jsonData[0].text2}</h5>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[1].title}
                    </p>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[1].title1}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].title2}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].text}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].text1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].text2}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[2].title}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[2].title1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[2].title2}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "18px",
                            color: "#000",
                        }}
                    >
                        {jsonData[2].text}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[2].text1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[2].text2}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[3].title}
                    </p>
                    <img
                        style={{
                            display: "block",
                            marginTop: "10px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        src={jsonData[3].image}
                        alt="car"
                    />
                    <h5>{jsonData[3].title1}</h5>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[3].title2}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[3].text}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[3].text1}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[3].text2}
                    </p>
                    <h4>{jsonData[4].title}</h4>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[4].title1}
                    </p>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[4].title1}
                    </p>
                    <h5>{jsonData[4].text}</h5>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[4].text1}
                    </p>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[4].text2}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].titile}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].titile1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].titile2}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].text}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].text1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[5].text2}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[6].title}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[6].title1}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[6].title2}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[6].text}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[6].text1}
                    </p>
                    <img
                        style={{
                            display: "block",
                            marginTop: "10px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        src={jsonData[6].image}
                        alt="truck"
                    />
                    <h5>{jsonData[6].text2}</h5>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[7].title1}
                    </p>
                    <ul>
                        <li
                            style={{
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[7].title1}
                        </li>
                        <li
                            style={{
                                marginLeft: "20px",
                                marginBottom: "15px",
                                color: "#000",
                            }}
                        >
                            {jsonData[7].title2}
                        </li>
                    </ul>
                    <p
                        style={{
                            fontSize: "17px",
                            color: "#000",
                        }}
                    >
                        {jsonData[7].text}
                    </p>
                    <p
                        style={{
                            fontSize: "25px",
                            color: "#000",
                        }}
                    >
                        {jsonData[7].text1}
                    </p>
                    <p
                        style={{
                            fontSize: "18px",
                            color: "#000",
                        }}
                    >
                        {jsonData[7].text2}
                    </p>
                    <img
                        style={{
                            display: "block",
                            marginTop: "10px",
                            marginLeft: "auto",
                            marginRight: "auto",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        src={jsonData[7].image}
                        alt="table"
                    />
                    <p
                        style={{
                            fontSize: "15px",
                            color: "#000",
                            marginTop: "10px",
                            textAlign: "center",
                        }}
                    >
                        {jsonData[8].title}
                    </p>
                    <p style={{ color: "#000", fontSize: "18px" }}>
                        {jsonData[8].title1}
                    </p>
                </div>
            </Container>
        </Layout>
    );
};
Motor.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query MotorQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Motor;
